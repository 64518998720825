.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.textToTranslateForm {
  color: white;
}

.translatedTextForm {
  color: white;
}

textarea {
  background-color: rgb(42, 41, 44);
  color: white;
  font-size: 2em;
}














/* HintBox.css */
.hintbox-hint {
  position: absolute;
  background-color: rgb(208, 208, 86);
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin-left: -205px; /* Add margin between HintBox and TextArea */
  width: 200px; /* Set a fixed width for the container */
  min-height: 50px; /* Set a minimum height for the container */
  overflow-wrap: break-word; /* Force long words to break into multiple lines */
  word-wrap: break-word; /* Force long words to break into multiple lines for older browsers */
}









.word {
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-right: 5px;
  font-size: 32px;
  color: white;
  margin-top: 50px;
}

.word:hover {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}
















/* THE CODE BELOW IS FOR THE TABS */

*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
  height: 200px;
  margin: 100px auto 0;
  word-break: break-all;
}

.bloc-tabs {
  display: flex;
}
.tabs {

  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  color: white;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs  {
  background: rgb(42, 41, 44);
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}




.content-tabs {
  flex-grow : 1;
}
.content {
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
  word-break: normal;
}
.active-content {
  display: block;
}

.suggestedTranslationDisplay {
  word-break: normal;
}




